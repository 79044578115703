import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import { hangupCall, startCall, answerCall, rtcOn, rtcOff, uaOn, uaOff, sendDTMF } from "../../SIPJS";
import * as backendModule from "../../../modules/backendModule";
import { internal__sendPrivateNotification } from "../../../modules/socketModule";

import Spinner from "../../customComponents/Spinner";
import CreateLead from "../../CreateLead";
import { animateBox } from "../../../modules/componentAnimation";
import CustomButtonSmall from "../../customComponents/ButtonSmall";
import moment from "moment";
import Dropdown from "../../customComponents/Dropdown";
import LeadEditPostAgent from "../../LeadEditPostAgent";

class CallStatsRecorder {
    #ID = null;
    #UserID = null;
    #Flags = {};

    #existingData = {};

    static flowTypes() {
        return {
            "1": "info",
            "2": "created_lead",
            "3": "existing_lead",
            "4": "existing_deal"
        };
    };
    static types() {
        return ["lead", "deal"];
    };

    checkShouldRecord(requireID = false) {
        if (!this.#UserID) return false;
        if (!this.#Flags?.["isShippmentAgent"]) return false;
        if (requireID && !this.#ID) return false;

        return true;
    };

    constructor(UserID, UserFlags) {
        this.#UserID = UserID;
        this.#Flags = UserFlags;
    };

    async initialCall(PhoneNumber, isIncomming) {
        if (!this.checkShouldRecord(!!this.#existingData["ID"])) return;

        return await axios({
            method: "POST",
            url: `${backendModule.backendURL}/callStats/addCallStat`,
            data: {
                PhoneNumber,
                CallIncomming: !!isIncomming,
                CallFlowType: "1"
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                this.#ID = res.data.data;
                this.#existingData = {
                    ID: res.data.data,
                    PhoneNumber,
                    CallIncomming: !!isIncomming,
                    CallFlowType: "1"
                };
                return true;
            };
            return false;
        }).catch(() => {
            return false;
        });
    };

    async changeFlow(flow, type, customID) {
        if (!this.checkShouldRecord(!!this.#existingData["ID"])) return;

        if (!CallStatsRecorder.flowTypes()[flow]) return false;
        if (!CallStatsRecorder.types().includes(type)) return false;
        if (!customID) return false;

        return await axios({
            method: "POST",
            url: `${backendModule.backendURL}/callStats/updateCallStat`,
            data: {
                ...this.#existingData,
                CallFlowType: flow,
                Type: type,
                CustomID: customID
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                this.#existingData = {
                    ...this.#existingData,
                    CallFlowType: flow,
                    Type: type,
                    CustomID: customID
                };
                return true;
            };
            return false;
        }).catch(() => {
            return false;
        });
    };
}

const SIPCallModal = (props) => {
    let retryTimeout = null;
    const sipSelector = useSelector(state => state.sip);
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const internalRef = React.useRef();
    const audioRef = React.useRef();
    const hadTrackingInit = React.useRef(false);
    const callStatsRecorderRef = React.useRef(new CallStatsRecorder(userInfoSelector?.ID, userInfoSelector?.Flags));

    const [curInfo, setCurInfo] = React.useState("");
    const [startedTime, setStartedTime] = React.useState();
    const [numberType, setNumberType] = React.useState();
    const [callAttempts, setCallAttempts] = React.useState(0);

    const closeModal = () => {
        clearTimeout(retryTimeout);
        uaOff("connected", onConnected);
        setCallAttempts(-10);
        try {
            hangupCall();
        } catch { };
        if (!internalRef.current) return props.onClose();
        internalRef.current.style.pointerEvents = "none";
        internalRef.current.animate([
            { opacity: getComputedStyle(internalRef.current).opacity },
            { opacity: 0 }
        ], {
            duration: 300,
            iterations: 1,
            easing: "ease-in-out",
            fill: "both"
        }).onfinish = props.onClose;
    };

    let onConnected = () => {
        uaOff("connected", onConnected);
        if (props.type === "outgoing") {
            retryTimeout = setTimeout(() => {
                setCurInfo();
                checkOrStartCall();
            }, 2000);
        } else {
            retryTimeout = setTimeout(() => {
                setCurInfo();
                try {
                    answerCall();
                } catch {
                    setCurInfo({ color: "gray", value: "Drugi agent je preuzeo poziv" });
                };
            }, 2000);
        };
    };

    const checkOrStartCall = async () => {
        let listenHandler = (e) => {
            setTimeout(() => {
                e.rtcSession.sendDTMF("82921087#");
                uaOff("newRTCSession", listenHandler);
            }, 5000);
        };

        if (props.type === "listen-in") {
            setNumberType("Preslušavanje");
            if (props.phone.length >= 6 && props.phone.length <= 7) {
                uaOn("newRTCSession", listenHandler);
                startCall(`*33${props.phone}`);
            } else {
                setCurInfo({ color: "#f74f4f", value: "Broj za preslušavanje nije validan" });
            };
        } else {
            if (props.phone.length > 7) {
                if (new RegExp(/^\+?\d{9,14}$/g).test(props.phone)) {
                    //setCurInfo("Validan (ext)");
                    setNumberType("eksterni");
                    try {
                        if (props.type === "outgoing") {

                            let isBlocked = await axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/blockedNumbers/checkIfNumberBlocked`,
                                data: {
                                    Number: props.phone,
                                    Type: 2
                                },
                                ...backendModule.axiosConfig
                            }).then(res => res.data).catch(() => backendModule.genericError);
                            if (isBlocked.status === "ok") {
                                if (isBlocked.data) {
                                    setCurInfo({ color: "#f74f4f", value: "Broj je blokiran, zabranjeni pozivi." });
                                    return;
                                };
                            };
                            axios({
                                method: "POST",
                                url: `${backendModule.backendURL}/sipConnectors/getSIPNumber`,
                                data: {
                                    number: props.phone
                                },
                                ...backendModule.axiosConfig
                            }).then(curNum => {
                                if (curNum.data.status === "error") {
                                    setCurInfo({ color: "#f74f4f", value: "Broj nije važeći, ili država nije prepoznata!" });
                                } else {
                                    startCall(curNum.data.data.Number);
                                };
                            }).catch((e) => {
                                setCurInfo({ color: "#f74f4f", value: "Server ne reagira" });
                            });
                        }
                    } catch {
                        setCurInfo({ color: "#f74f4f", value: "Došlo je do greške prilikom uspostavljanja poziva" });
                    };
                } else {
                    setCurInfo({ color: "#f74f4f", value: "Izlazni broj nije validan" });
                };
            } else {
                if (props.phone.length < 6) {
                    setCurInfo({ color: "#f74f4f", value: "Interni broj nije validan" });
                } else {
                    //setCurInfo("Validan (int)")
                    setNumberType("interni");
                    if (props.type === "outgoing") {
                        try {
                            startCall(props.phone);
                        } catch {
                            setCurInfo({ color: "#f74f4f", value: "Došlo je do greške prilikom uspostavljanja poziva" });
                        };
                    };
                };
            };
        };
    };

    React.useEffect(() => {
        let interval = null;
        if (sipSelector?.call?.status === "callStatus/ACTIVE") {
            if (!startedTime) {
                setStartedTime(Date.now());
            };
            interval = setInterval(() => {
                if (!startedTime) return;

                let uptime = ((Date.now() - startedTime) / 1000).toFixed(0);
                let minutes = 0;
                let seconds = 0;
                while (uptime >= 60) {
                    minutes += 1;
                    uptime -= 60;
                };
                minutes = String(minutes);
                seconds = String(uptime);
                if (minutes.length === 1) minutes = `0${minutes}`;
                if (seconds.length === 1) seconds = `0${seconds}`;

                let tmp = internalRef?.current?.querySelector(".modal__sipCall__sipStatus__main__inProgress");
                if (!tmp) return;
                tmp.textContent = `${minutes}:${seconds}`;
            }, 1000);
        };
        return () => {
            clearInterval(interval);
        };
    }, [sipSelector?.call?.status, startedTime]);

    React.useEffect(() => {
        const rtcEndedHandle = (e) => {
            if (e?.originator === "remote") {
                setCurInfo({ color: "green", value: "Poziv završen" });
            } else {
                closeModal();
            };
        };
        const rtcFailedHandle = (e) => {
            switch (e?.cause) {
                case "Canceled":
                    setCurInfo({ color: "gray", value: "Poziv prekinut" });
                    break;
                case "Rejected":
                    setCurInfo({ color: "#f74f4f", value: "Poziv odbijen (korisnik se ne želi javiti)" });
                    break;
                case "Unavailable":
                    setCurInfo({ color: "#f74f4f", value: "Nepostojeći broj / Odbijen poziv" });
                    break;
                case "Busy":
                    setCurInfo({ color: "#f74f4f", value: "Zauzet" });
                    break;
                default:
                    setCurInfo({ color: "#f74f4f", value: "Generična greška prilikom uspostavljanja poziva" });
                    break;
            };
        };
        const uaDisconnectHandle = e => {
            if (e?.code === 1006) {
                setCallAttempts(d => d + 1);
            };
        };

        rtcOn("ended", rtcEndedHandle);
        rtcOn("failed", rtcFailedHandle);
        uaOn("disconnected", uaDisconnectHandle);
        return () => {
            rtcOff("ended", rtcEndedHandle);
            rtcOff("failed", rtcFailedHandle);
            uaOff("disconnected", uaDisconnectHandle);
            clearTimeout(retryTimeout);
        };
    }, []);

    React.useEffect(() => {
        if (callAttempts === 1) {
            setCurInfo({ color: "orange", value: "Ponovni pokušaj..." });
            uaOn("connected", onConnected);
        } else if (callAttempts > 1) {
            setCurInfo({ color: "#f74f4f", value: "WS server ne reagira, pokušajte ponovo" });
            uaOff("connected", onConnected);
        };

        return () => {
            uaOff("connected", onConnected);
        };
    }, [callAttempts]);

    React.useEffect(() => {

        if (sipSelector?.call?.direction === "callDirection/INCOMING" && sipSelector?.call?.status === "callStatus/STARTING") {
            if (!audioRef.current) {
                audioRef.current = new Audio("/audio/ring.mp3");
                audioRef.current.loop = true;
                audioRef.current.play();
            };
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                try {
                    audioRef.current.close();
                } catch { };
                audioRef.current = null;
            };
        };
    }, [sipSelector?.call]);

    React.useEffect(() => {
        checkOrStartCall();
    }, []);

    React.useEffect(() => {
        if (hadTrackingInit.current) return;

        if (sipSelector?.call?.status === "callStatus/ACTIVE") {
            hadTrackingInit.current = true;
            callStatsRecorderRef.current.initialCall(props.phone, props.type === "incomming");
        };
    }, [sipSelector?.call]);

    return <div className="modal__sipCall" ref={internalRef}>
        <div className="modal__sipCall__minimize" onClick={props.hideModal}>

        </div>
        <div className="modal__sipCall__call">
            <div className="modal__sipCall__img">
                <img src="/images/undefined.png" />
            </div>
            <div className="modal__sipCall__callerInfo">
                <p className="modal__sipCall__callerInfo__callerName">Ime prezime</p>
                <p className="modal__sipCall__callerInfo__callerPhone">{`(${numberType ?? ""}) ${props.phone}`}</p>
            </div>
            <div className="modal__sipCall__sipStatus">
                {!curInfo?.value ? <>
                    {(sipSelector?.call?.status !== "callStatus/ACTIVE" && !(sipSelector?.call?.direction === "callDirection/INCOMING" && sipSelector?.call?.status === "callStatus/STARTING")) && <Spinner style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "20px"
                    }} color="white" />}
                    <span className="modal__sipCall__sipStatus__main">{(() => {
                        switch (sipSelector?.call?.status) {
                            case "callStatus/IDLE": return "Povezivanje...";
                            case "callStatus/STARTING":
                                if (sipSelector?.call?.direction === "callDirection/INCOMING") {
                                    return "Dolazni poziv";
                                } else {
                                    return "Uspostavljam poziv...";
                                };
                            case "callStatus/STOPPING": return "Zaustavljam poziv...";
                            case "callStatus/ACTIVE": return <span className="modal__sipCall__sipStatus__main__inProgress">00:00</span>;
                            default: return "SIP server nije aktivan!";
                        }
                    })()}</span>
                </> : <span style={{ color: curInfo.color }}>{curInfo.color === "orange" && <Spinner style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "20px"
                }} color="orange" />}{curInfo.value}</span>}
            </div>
            <div className="modal__sipCall__sipButtons">
                {!curInfo && sipSelector?.call?.status !== "callStatus/IDLE" && sipSelector?.call?.status !== "callStatus/STARTING" && <>

                </>}
            </div>

            <div className="modal__sipCall__sipActions">
                {(sipSelector?.call?.direction === "callDirection/INCOMING" && sipSelector?.call?.status === "callStatus/STARTING") && <Spinner color="white" style={{ width: "32px", height: "32px", display: "none" }} />}
                {(sipSelector?.call?.direction === "callDirection/INCOMING" && sipSelector?.call?.status === "callStatus/STARTING") && <div onClick={(e) => {
                    e.target.style.display = "none";
                    e.target.parentNode.querySelector(".customComponents__spinner").style.display = null;
                    answerCall();
                }} className="modal__sipCall__sipActions__green">Prihvati poziv</div>}
                {numberType === "Preslušavanje" && <div onClick={e => {
                    animateBox(e, <SendNotification username={props.username} />);
                }} className="modal__sipCall__sipActions__yellow">Pošalji notifikaciju</div>}
                <div onClick={closeModal} className="modal__sipCall__sipActions__red">Prekini poziv</div>

            </div>
        </div>
        <CustomerData phone={props.phone} callStatsRecorder={callStatsRecorderRef.current} />

    </div>
};

const SendNotification = props => {
    const textRef = React.useRef();

    const sendNotification = () => {
        if (!textRef?.current?.value) return;

        internal__sendPrivateNotification(props.username, textRef.current.value);
        return props.onClose();
    };

    return <div className="modal__sipCall__sendNotification">
        <div className="modal__sipCall__sendNotification__wrap">
            <textarea ref={textRef}></textarea>
            <div className="modal__sipCall__sendNotification__wrap__btns">
                <CustomButtonSmall theme="dark" accent="#2E2E41" value="Pošalji" style={{ border: "1px solid #999FAE" }} onClick={sendNotification} />
                <CustomButtonSmall theme="dark" accent="#2E2E41" value="Izlaz" onClick={props.onClose} style={{ border: "1px solid rgb(233, 86, 86)" }} />
            </div>
        </div>
    </div>
};


const CustomerData = props => {
    const [leads, setLeads] = React.useState();
    const [deals, setDeals] = React.useState();
    const [selected, setSelected] = React.useState();
    const [dealAction, setDealAction] = React.useState();
    const [dealStatus, setDealStatus] = React.useState();
    const [dealSaveStatus, setDealSaveStatus] = React.useState();
    const [testLead, setTestLead] = React.useState();

    const statusCodesSelector = useSelector(state => state?.statusCodes ?? {});

    const saveDeal = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/editDealStatusShippmentAgent`,
            data: {
                ID: selected[0].ID,
                Status: dealStatus
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setLeads(res.data)
            };
        }).catch(() => {
        });
    }
    React.useEffect(() => {
        if (!dealStatus) return;
        saveDeal();
    }, [dealStatus])

    const getTestLead = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllLeadsDashboard`,
            data: {
                ID: testLead,
                limit: 1,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSelected([res.data.data[0], "lead"])
            };
        }).catch(() => {
        });
    }
    const getLeads = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/leads/getAllLeadsDashboard`,
            data: {
                filters: [
                    { name: "PhoneNumber", op: "like", value: props?.phone.toString()?.substring(1) },
                    { name: "Status", op: "neq", value: 100 },
                ],
                limit: 2,
                orders: [{ name: "createdAt", order: "desc" }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setLeads(res.data)
            };
        }).catch(() => {
        });
    }

    const getDeals = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/deals/getAllDealsDashboard`,
            data: {
                filters: [{ name: "PhoneNumber", op: "like", value: props?.phone.toString()?.substring(1) }],
                limit: 2,
                orders: [{ name: "createdAt", order: "desc" }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setDeals(res.data)
            };
        }).catch(() => {
        });
    }

    React.useEffect(() => {
        if (!props.phone) return;
        getLeads();
        getDeals();

    }, [props.phone])

    React.useEffect(() => {
        if (!testLead) return;
        getTestLead();
    }, [testLead])

    React.useEffect(() => {
        if (!Array.isArray(selected)) return;
        if (selected[0] && selected[1]) {
            props.callStatsRecorder.changeFlow(
                selected[1] === "lead" ? "3" : "4",
                selected[1],
                selected[0]?.ID
            );
        };
    }, [selected]);


    return <div className="modal__sipCall__customerData">
        {!selected && <div className="modal__sipCall__customerData__first">

            <p>Leadovi</p>
            {leads?.status === "ok" ? <div className="modal__sipCall__customerData__data">
                {leads.data.map(lead => {
                    return <>
                        <p>{lead.ID}</p>
                        <p>{moment(lead.createdAt).format("DD/MM/YY hh:mm")}</p>
                        <p>{lead.FirstLastName}</p>
                        <p>{statusCodesSelector["leads"][lead.Status]}</p>
                        <p onClick={() => { setSelected([lead, "lead"]) }}>Uredi</p>
                    </>
                })}
            </div> : <Spinner />}
            <p>Dealovi</p>
            {deals?.status === "ok" ? <div className="modal__sipCall__customerData__data">
                {deals.data.map(lead => {
                    return <>
                        <p>{lead.ID}</p>
                        <p>{moment(lead.createdAt).format("DD/MM/YY hh:mm")}</p>
                        <p>{lead.FirstLastName}</p>
                        <p>{statusCodesSelector["deals"][lead.Status]}</p>
                        <p onClick={() => { setSelected([lead, "deal"]) }}>Uredi</p>
                    </>
                })}
            </div> : <Spinner />}
            <div style={{
                margin:" 20px auto",
                width:"300px",
                backgroundColor:"#6664E5",
                textAlign:"center",
                gridTemplateColumns:"1fr",
                padding:"10px",
                borderRadius:"30px",
                cursor:"pointer"

            }} onClick={e => {
                animateBox(e, <CreateLead phoneNumber={props.phone} setTestLead={setTestLead} onChange={e => {
                    props.callStatsRecorder.changeFlow("2", "lead", e);
                }} />);
            }} className="modal__sipCall__sipActions__yellow">Kreiraj lead</div>
            <p>Napomena: Ukoliko izaberete uredjivanje leada sistem ce automatski vas postaviti kao odgovornu osobu i obavezni ste dovrsiti obradu leada,
                ukoliko izaberete deal vi postajete odgovorna osoba za deal i takodjer morate zavrsiti obradu istog. Obratite paznju na datume kreiranja i statuse
                dealova i leadova kako biste izabrale najrelevantniji za dalju obradu, sve izmjene na dealu/leadu se mogu napraviti iskljucivo dok je poziv u toku.
            </p>
        </div>}
        {selected && <div className="modal__sipCall__customerData__selected">
            <p onClick={() => { setSelected(null) }}>{`<-`} Back</p>
            {selected[1] === "lead" ? <div className="modal__sipCall__customerData__selected__lead">

                <div className="modal__sipCall__customerData__selected__lead__inputs">
                    <LeadEditPostAgent lead={selected[0]} />

                </div> 
            </div> : <div className="modal__sipCall__customerData__selected__deal">
                <p>
                    Izaberite razlog poziva:
                </p>
                <Dropdown theme="dark" accent="rgb(150, 150, 152)" data={[
                    { name: "Problemi sa dostavom", value: 0 },
                    { name: "Klijent zeli odustati", value: 1 },
                    { name: "Reklamacija", value: 2 },
                    { name: "Informativno", value: 3 }
                ]} selected={0} onChange={e => {
                    setDealAction(e.value)
                }} />
                <div className="modal__sipCall__customerData__selected__deal__buttons">
                    {dealAction === 0 && <>
                        <p onClick={() => {
                            setDealStatus(99)
                            setDealSaveStatus(100)
                        }}>Deal spasen</p>
                        <p onClick={() => {
                            setDealStatus(98)
                            setDealSaveStatus(99)
                        }}>Deal nije spasen</p>
                    </>}
                    {dealAction === 1 && <>
                        <p onClick={() => {
                            setDealStatus(selected[0].Status)
                            setDealSaveStatus(100)
                        }}>Deal spasen</p>
                        <p onClick={() => {
                            setDealStatus(50)
                            setDealSaveStatus(99)
                        }}>Deal nije spasen</p>
                    </>}
                    {dealAction === 2 && <>
                        <p onClick={() => {
                            setDealStatus(selected[0].Status)
                            setDealSaveStatus(100)
                        }}>Deal spasen</p>
                        <p onClick={() => {
                            setDealStatus(selected[0].Status)
                            setDealSaveStatus(selected[0].DealSaveStatus)
                        }}>Potrebno proslijediti kolegici za reklamacije</p>
                    </>}

                    {dealAction === 3 && <>
                        <p onClick={() => {
                            setDealStatus(selected[0].Status)
                            setDealSaveStatus(selected[0].DealSaveStatus)
                        }}>Dodatne informacije o kapsulama</p>
                        <p onClick={() => {
                            setDealStatus(selected[0].Status)
                            setDealSaveStatus(selected[0].DealSaveStatus)
                        }}>Način korištenja kapsula</p>
                        <p onClick={() => {
                            setDealStatus(selected[0].Status)
                            setDealSaveStatus(selected[0].DealSaveStatus)
                        }}>Dodatne informacije u vezi firme i načina poslovanja</p>
                    </>}
                </div>



            </div>}
        </div>}


    </div>
};

export default SIPCallModal;
export { CallStatsRecorder }