import React, { useState } from "react";
import "./index.scss";

import { useDispatch, useSelector, Provider } from "react-redux";
import { hangupCall, stopCall, startCall, answerCall, rtcOn, rtcOff, uaOn, uaOff, setAcceptIncomming } from "../../components/SIPJS";
import { createRandomString } from "../../modules/miscModule";

import * as modalActions from "../../actions/modalActions";
import * as backendModule from '../../modules/backendModule'
import axios from "axios";
import moment, { lang } from "moment";

import Spinner from '../../components/customComponents/Spinner'
import Dropdown from '../../components/customComponents/Dropdown'
import AudioPlayer from "../../components/customComponents/AudioPlayer";
import CustomButtonSmall from "../../components/customComponents/ButtonSmall";
import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { updateActivity } from '../../actions/activityAction'
import HeaderNotifications from "../../components/Notifications";
import translate from '../../routes/CRM/translate.json'
import { animateBox } from "../../modules/componentAnimation";
import YesNoModal from "../../components/ModalController/modals/YesNoModal";

let HandshakeID = null;
const LeadEditPostAgent = (props) => {
    let userData = useSelector((state) => state.userData);
    let statusCodes = useSelector((state) => state.statusCodes);
    let chatUsers = useSelector((state) => state.chatUsers);
    let sipSelector = useSelector(state => state.sip);
    let illnessesSelector = useSelector(state => state?.statusCodes?.illnesses ?? {});
    let language = useSelector(state => state.language)
    let userDispatch = useDispatch();
    // let languageDispatch = useDispatch();
    translate = translate
    const [spinner, setSpinner] = React.useState(true);
    const [callSpinner, setCallSpinner] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(1);
    const [leadStatus, setLeadStatus] = React.useState(props.lead?.Status);
    const [leadCountry, setLeadCountry] = React.useState(props.lead?.Country);
    const [leadCity, setLeadCity] = React.useState(props.lead?.City);
    const [addressesRS, setAddressesRS] = React.useState();
    const [allCities, setAllCities] = React.useState([]);


    const [callAgain, setCallAgain] = React.useState(false);
    const [callAgainFinal, setCallAgainFinal] = React.useState();

    const [deliveryDate, setDeliveryDate] = React.useState(Date.now());

    const [canClose, setCanClose] = React.useState(false);
    const [allCountries, setAllCountries] = React.useState();
    const [allArticles, setAllArticles] = React.useState([]);
    const [selectedArticles, setSelectedArticles] = React.useState([]);
    const [postInfo, setPostInfo] = React.useState(props.lead?.AdditionalShippmentInfo);
    const [badLeadComm, setBadLeadComm] = React.useState(true)
    const [hadCall, setHadCall] = React.useState(false);
    const [leadFinished, setLeadFinished] = React.useState(false);

    const [leadStreet, setLeadStreet] = React.useState();
    const [leadHouseNumber, setLeadHouseNumber] = React.useState(props.lead?.HouseNumber);
    const [leadPhone, setLeadPhone] = React.useState(props.lead?.PhoneNumber);
    const [leadName, setLeadName] = React.useState(props.lead?.FirstLastName ? props.lead?.FirstLastName.split(" ")[0] : null);
    const [leadLastName, setLeadLastName] = React.useState(props.lead?.FirstLastName ? props.lead?.FirstLastName.split(" ")[1] : null);
    const [leadEmail, setLeadEmail] = React.useState(props.lead?.Email ?? "");
    const [leadBirthYear, setBirthYear] = React.useState(props.lead?.BirthYear === null ? 1 : props.lead?.BirthYear)
    const [doubleCheck, setDoubleCheck] = React.useState(false);
    const [doubleCheckDone, setDoubleCheckDone] = React.useState(false)
    const [canCall, setCanCall] = React.useState(true)
    const [sipCity, setSIPCity] = React.useState()
    const [shouldPrintGuarantee, setShouldPrintGuarantee] = React.useState(true);
    const [selectedGender, setSelectedGender] = React.useState(props.lead?.Gender)
    const [recurringPayment, setRecurringPayment] = React.useState(props.lead?.isRecurringPayment)
    const [recurringPaymentJMBG, setRecurringPaymentJMBG] = React.useState(props.lead?.isRecurringPaymentJMBG)
    const [clientIllness, setClientIllness] = React.useState([]);

    const [successfulCall, setSuccessfulCall] = React.useState(false);
    const [agentCallAttempts, setAgentCallAttempts] = React.useState(0);
    const [errorMsg, setErrorMsg] = React.useState();

    const callAgainDate = React.useRef('');
    const callAgainTime = React.useRef('');
    const comment = React.useRef('');
    const mainRef = React.useRef();
    const timerRef = React.useRef(0);
    const timestampsRef = React.useRef([]);
    const oldStatusRef = React.useRef(props.lead?.Status);
    const audioRef = React.useRef();
    const callInfoRef = React.useRef();


    //aditional data
    const [comments, setComments] = React.useState();
    const [leadHistory, setLeadHistory] = React.useState();

    //calls
    const [curInfo, setCurInfo] = React.useState("");
    const [startedTime, setStartedTime] = React.useState();

    const checkDeliveryDateActive = (index) => {
        let dd = moment(deliveryDate);
        let curDelivery = getDeliveryDates()[index];

        if (dd.isBefore(curDelivery) && index === 0) return true;
        if (dd.format("DD-MM-YYYY") === curDelivery.format("DD-MM-YYYY")) return true;
        return false;
    };

    const getDeliveryDates = () => {
        let tmp = [];

        while (tmp.length < 3) {
            let a = null;
            if (tmp.length > 0) a = moment(tmp[tmp.length - 1]); else a = moment();
            if (tmp.length !== 0) a.add(1, "days");

            if (a.day() === 6) a = a.add(2, "days");
            if (a.day() === 0) a = a.add(1, "days");

            tmp.push(a);
        };

        return tmp;
    };

   

    React.useEffect(() => {
        userDispatch(updateActivity(`Lead - neaktivan`));
    }, []);
    React.useEffect(() => {
        if (userData.userData === null) {
            onCloseHandle();
        } else {
            if (!allCountries) getCountries();
        };

    }, [userData]);

    React.useEffect(() => {
        if (!mainRef.current) return
        mainRef.current.animate([
            { opacity: getComputedStyle(mainRef.current).opacity },
            { opacity: 1 }
        ], {
            duration: 300,
            delay: 150,
            iterations: 1,
            easing: 'ease-in-out',
            fill: 'both'
        });
        try {
            const tmpSelectedArticles = JSON.parse(props.lead?.Articles);
            const final = [];
            if (Array.isArray(tmpSelectedArticles)) {
                for (let tmp of tmpSelectedArticles) {
                    if (!tmp.id) tmp.id = generateArticleID();
                    if (!tmp.data.id || !tmp.data.quantity || !tmp.data.text) continue;
                    tmp.data.quantity = Number(tmp.data.quantity);
                    if (isNaN(tmp.data.quantity)) continue;
                    final.push(tmp);
                };
                if (final.length > 0) setSelectedArticles(final);
            };
        } catch { };
    }, []);
    const getAddressesRS = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/common/getAllStreets`,
            data: {
                CityID: leadCity
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAddressesRS(res.data.data)
            }
        })
    }
    React.useEffect(() => {
        if (!leadCity) return
        if (leadCountry !== 'RS') return
        getAddressesRS()
    }, [leadCity, leadCountry])
    React.useEffect(() => {
        if (!leadCountry) return;
        if (!allCountries) return;
        if (allCountries.length === 0) return;
        getCities();
        getArticles();

        if (!["BA", "RS"].includes(leadCountry)) {
            setRecurringPayment(false);
            setRecurringPaymentJMBG("");
        };
    }, [leadCountry, allCountries]);

    React.useEffect(() => {
        if (activeTab === 3) {
            setTimeout(getLeadHistory, 0);
        } else {
            if (leadHistory) setLeadHistory(null);
        };
        if (activeTab === 2) {
            setTimeout(getAllComments, 0);
        } else {
            setComments(null);
        };;
    }, [activeTab])

    React.useEffect(() => {
        if (leadStatus !== 2 || leadStatus !== "2") {
            if (callAgainFinal && successfulCall) setCallAgainFinal(null);
        };
    }, [leadStatus]);

    const onCloseHandle = () => {
        props.onClose();
        props.onClose2 && props.onClose2();
    };

    const generateArticleID = () => {
        return `${Date.now()}-${Math.floor(Math.random() * 10000)}`;
    };

    const getCountries = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/countries/getAllCountries`,
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                setAllCountries(res.data.data);
                setSpinner(false);
            } else {
                setTimeout(() => getCountries(), 1000);
            };
        }).catch(() => {
            setTimeout(() => getCountries(), 1000);
        });
    };

    const getAllComments = () => {
        setComments();
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllComments`,
            data: {
                ReferenceName: 'lead',
                ReferenceID: props.lead?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setComments(res.data.data)
            };
        });
    }
    const postComment = () => {
        if (!comment.current.value) return;
        setSpinner(true);
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/addComment`,
            data: {
                ReferenceName: 'lead',
                ReferenceID: props.lead?.ID,
                Comment: comment.current.value.trim()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllComments();
            };
        }).catch(() => null).finally(() => {
            setSpinner(false);
        });
    };
    const getLeadHistory = () => {
        setLeadHistory();
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllHistory`,
            data: {
                ReferenceName: 'lead',
                ReferenceID: props.lead?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setLeadHistory(res.data.data)
            }
        });
    };
    const getCities = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/common/getAllCities`,
            data: {
                Country: leadCountry
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllCities(res.data.data)
            };
        }).catch(() => null);
    };
    const getArticles = async () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/articles/getAllArticlesAgent`,
            data: {
                CountryID: allCountries.find(t => t.CountryCode === leadCountry)?.ID ?? null
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                setAllArticles(res.data.data)
            };
        }).catch(() => null);
    };
    const saveData = () => {
        setErrorMsg(false)
        let rsAddressNum = /^((bb|BB|b\.b\.|B\.B\.)(\/[-a-zžćčđšA-ZĐŠĆŽČ_0-9]+)*|(\d(-\d)?[a-zžćčđšA-ZĐŠĆŽČ_0-9]{0,2})+(\/[-a-zžćčđšA-ZĐŠĆŽČ_0-9]+)*)$/;
        let rsAddress = /^([\-\a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)( [\-\a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)*$/;
        let rsName = /^([\-\a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)( [\-\a-zžćčđšA-ZĐŠĆŽČ_0-9\.]+)*$/;

        // let regex = /^\d{1,3}(\/[a-zA-Z]|\d{1,3})?(\/\d{1,4})?$/;
        if (leadCountry === "RS" && leadHouseNumber && `${leadName} ${leadLastName}` && leadStreet) {
            let tmpHouseNumber = leadHouseNumber.trim();
            let tmpName = `${leadName} ${leadLastName}`.trim();
            let tmpStreet = leadStreet.trim();
            if (!rsAddressNum.test(tmpHouseNumber)) {
                return setErrorMsg("Broj kuce ne smije sadrzavati specijalne karaktere ni interpunkcijske znake kao ni slova koja ne pripadaju  abecedi naseg govornog podrucja obavezan format je broj , bb ili broj zgrade/stana")
            }
            if (!rsAddress.test(tmpStreet)) {
                return setErrorMsg("Adresa ne smije sadrzavati nikakve specijalne karaktere ni interpunkcijske znake kao ni slova koja ne pripadaju nasoj abecedi naseg govornog podrucja")
            }
            if (!rsName.test(tmpName)) {
                return setErrorMsg("Ime i prezime moraju biti ispisani abecedom naseg govornog podrucja bez specijalnih karaktera kao i dodatnih imena, prezimena i nadimaka")
            }
        }
        if (!leadName) return;
        if (!leadLastName && Number(leadStatus) === 100) return;
        let data = {
            ID: props.lead?.ID,
            Email: leadEmail,
            Status: Number(oldStatusRef.current),
            FirstLastName: `${leadName} ${leadLastName}`,
            BirthYear: leadBirthYear,
            PhoneNumber: leadPhone,
            Address: leadStreet,
            City: Number(leadCity),
            Country: leadCountry,
            ScheduledCallTime: Number(leadStatus) === 2 ? callAgainFinal : null,
            HouseNumber: leadHouseNumber,
            Articles: selectedArticles.filter(t => t.data !== null),
            AdditionalShippmentInfo: postInfo,
            ScheduledDelivery: deliveryDate,
            shouldPrintGuarantee,
            Gender: selectedGender,
            isRecurringPayment: !!recurringPayment,
            isRecurringPaymentJMBG: recurringPayment ? recurringPaymentJMBG : "",
            Illnesses: clientIllness,
            EditAgent: true
        };

        setSpinner(true);
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/leads/editLeadAgent`,
            data,
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === 'ok') {
                if (leadStatus !== 1) {
                    setCanClose(true);
                };
            };
        }).finally(() => setSpinner(false));
    };
    const finishLead = () => {
        setLeadFinished(true);
        setSpinner(true);
        let data = {
            ID: props.lead?.ID,
            Email: leadEmail,
            Status: Number(leadStatus),
            FirstLastName: `${leadName} ${leadLastName}`,
            PhoneNumber: leadPhone,
            Address: leadStreet,
            City: Number(leadCity),
            Country: leadCountry,
            ScheduledCallTime: Number(leadStatus) === 2 ? callAgainFinal : null,
            HouseNumber: leadHouseNumber,
            Articles: selectedArticles.filter(t => t.data !== null),
            AdditionalShippmentInfo: postInfo,
            ScheduledDelivery: deliveryDate,
            BirthYear: leadBirthYear,
            Gender: selectedGender,
            Illnesses: clientIllness,
        };

        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/leads/editLeadAgent`,
            data,
            ...backendModule.axiosConfig
        }).then(sl => {
            if (sl.status === "error") {
                setSpinner(false);
                return setLeadFinished(false);
            };
            axios({
                method: 'POST',
                url: `${backendModule.backendURL}/leads/closeLeadAgent`,
                data: {
                    ID: props.lead?.ID,
                    hadCall,

                    successfulCall
                },
                ...backendModule.axiosConfig
            }).then((res) => {
                if (res.data.status === 'ok') {
                    onCloseHandle()
                    if (props.finishLead) props.finishLead()
                    if (!mainRef.current) return
                    mainRef.current.animate([
                        { opacity: getComputedStyle(mainRef.current).opacity },
                        { opacity: 0 }
                    ], {
                        duration: 300,
                        iterations: 1,
                        easing: 'ease-in-out',
                        fill: 'both'
                    });
                    setSpinner(false);
                } else {
                    setLeadFinished(false);
                    setSpinner(false);
                };
            }).catch(() => {
                setLeadFinished(false);
                setSpinner(false);
            });
        }).catch(() => {
            setLeadFinished(false);
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        let interval = null;
        if (sipSelector?.call?.status === "callStatus/IDLE") {
            userDispatch(updateActivity(`Lead - neaktivan`));
        } else {
            userDispatch(updateActivity(`Lead - poziv`));
        };
        if (sipSelector?.call?.direction === "callDirection/OUTGOING" && sipSelector?.call?.status === "callStatus/STARTING") {
            if (!audioRef.current) {
                audioRef.current = new Audio("/audio/outgoing.mp3");
                audioRef.current.loop = true;
                audioRef.current.play();
                setAgentCallAttempts(a => a += 2);
            };
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            };
        }
        if (sipSelector?.call?.status === "callStatus/ACTIVE" && sipSelector?.call?.direction === "callDirection/OUTGOING") {
            if (!successfulCall) setSuccessfulCall(true);
            if (!startedTime) setStartedTime(Date.now());
            interval = setInterval(() => {
                if (!startedTime) return;

                let uptime = ((Date.now() - startedTime) / 1000).toFixed(0);
                let minutes = 0;
                let seconds = 0;
                while (uptime >= 60) {
                    minutes += 1;
                    uptime -= 60;
                };
                minutes = String(minutes);
                seconds = String(uptime);
                timerRef.current = (Number(minutes) * 60) + Number(seconds);
                if (minutes.length === 1) minutes = `0${minutes}`;
                if (seconds.length === 1) seconds = `0${seconds}`;

                setCurInfo({ color: "white", value: `${minutes}:${seconds}` });
            }, 1000);
        } else {
            if (startedTime) setStartedTime();
        };
        return () => {
            clearInterval(interval);
        };
    }, [sipSelector?.call?.status, startedTime]);

    const checkDoubleLead = () => {
        let url = `${backendModule.backendURL}/leads/getAllLeadsDashboard`

        axios({
            method: "POST",
            url,
            data: {
                filters: [
                    { name: "PhoneNumber", op: "eq", value: leadPhone },
                    { name: "ID", op: "neq", value: props.lead?.ID }
                ],
                orders: [{ name: "updatedAt", order: "desc" }],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length > 0) {
                    return setDoubleCheck(true);
                }

            }
            return setDoubleCheck(false);
        }).catch(() => {
            return setDoubleCheck(false);
        });
    };
    React.useEffect(() => {

        if (leadPhone.length > 9) {
            checkDoubleLead()
        }
    }, [leadPhone])
    React.useEffect(() => {
        const rtcEndedHandle = (e) => {
            setCurInfo({ color: "green", value: "Poziv završen" });
            setHadCall(true);

            if (HandshakeID) {
                let allColors = [
                    "rgb(244, 109, 78)",
                    "rgb(78, 255, 139)",
                    "#9797B8",
                    "rgb(78, 164, 255)",
                    "rgb(244, 187, 78)",
                    "rgb(78, 228, 244)",
                    "rgb(244, 78, 210)"
                ];
                let finalTimestamps = [];
                for (let item of timestampsRef.current) {
                    if (finalTimestamps.length === 0) {
                        finalTimestamps.push({
                            name: item.step,
                            start: 0,
                            end: item.time,
                            color: allColors.pop()
                        });
                    } else {
                        let tmp = finalTimestamps[finalTimestamps.length - 1];
                        finalTimestamps.push({
                            name: item.step,
                            start: tmp.end,
                            end: item.time,
                            color: allColors.pop()
                        });
                    };
                };
                if (finalTimestamps.length > 0) {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/callRecordings/updateRecordingByUIDAndHandshake`,
                        data: {
                            CustomID: props.lead?.ID,
                            HandshakeID: HandshakeID,
                            Timestamps: finalTimestamps
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };
            };
        };
        const rtcFailedHandle = (e) => {
            switch (e?.cause) {
                case "Canceled":
                    setCurInfo({ color: "gray", value: "Poziv prekinut" });
                    break;
                case "Rejected":
                    if (!hadCall) setHadCall(true);
                    setCurInfo({ color: "#f74f4f", value: "Poziv odbijen (korisnik se ne želi javiti)" });
                    break;
                case "Unavailable":
                    setCurInfo({ color: "#f74f4f", value: "Nepostojeći broj / Odbijen poziv" });
                    break;
                case "Busy":
                    if (!hadCall) setHadCall(true);
                    setCurInfo({ color: "#f74f4f", value: "Zauzet" });
                    break;
                default:
                    setCurInfo({ color: "#f74f4f", value: e?.cause });
                    break;
            };
        };

        rtcOn("ended", rtcEndedHandle);
        rtcOn("failed", rtcFailedHandle);
        return () => {
            rtcOff("ended", rtcEndedHandle);
            rtcOff("failed", rtcFailedHandle);
        };
    }, []);

    React.useEffect(() => {
        setAcceptIncomming(false);

        return () => setAcceptIncomming(true);
    }, []);

    const translateStatusCodes = (status) => {
        if (language === "BA" || language === "SI") {
            return status
        } else {
            return {
                "Obrada": "Procesare",
                "Pozvati ponovo": "Reapelati",
                "Loš lead": "Prospect slab",
                "Odbijen lead": "Prospect respins",
                "Dobar lead": "Prospect bun"
            }[status]
        }

    }
    const ConfirmNoGuarantee = props2 => {
        return <div className="component__agentCall__noGuarantee">
            <div className="component__agentCall__noGuarantee__wrap">
                <h2 align="center">Da li ste sigurni?</h2>
                <p align="center">Ovu opciju gasite samo ako klijent NE ŽELI garanciju uz proizvod</p>

                <br />
                <div className="component__agentCall__noGuarantee__wrap__buttons">
                    <CustomButtonSmall style={{ width: "180px" }} theme="dark" accent="green" value="Klijent želi garanciju" onClick={props2.onClose} />
                    <CustomButtonSmall style={{ width: "180px" }} theme="dark" accent="#bb2c2c" value="Klijent NE ŽELI garanciju" onClick={() => {
                        props2.onClose().then(() => setShouldPrintGuarantee(() => false));
                    }} />
                </div>
            </div>
        </div>
    };

    return <div className="component__agentCall" ref={mainRef} >
        <HeaderNotifications />
        <div className="component__agentCall__info"  style={{width:"100%"}}>
            <h1 className="component__agentCall__info__head">{props.lead?.Name}
            </h1>


            <div className="component__agentCall__info__tabs">
                <p onClick={(e) => {
                    setActiveTab(0)
                    e.currentTarget.parentNode.querySelectorAll('.component__agentCall__info__tabs__single ').forEach(item => {
                        item.classList.remove('component__agentCall__info__tabs__single--active')
                    })
                    e.currentTarget.classList.add('component__agentCall__info__tabs__single--active')
                }}
                    className="component__agentCall__info__tabs__single component__agentCall__info__tabs__single--active">{translate[language].upute}</p>
                <p onClick={(e) => {
                    setActiveTab(1)
                    e.currentTarget.parentNode.querySelectorAll('.component__agentCall__info__tabs__single ').forEach(item => {
                        item.classList.remove('component__agentCall__info__tabs__single--active')
                    })
                    e.currentTarget.classList.add('component__agentCall__info__tabs__single--active')
                }}
                    className="component__agentCall__info__tabs__single">{translate[language].osnovno}</p>
                <p onClick={(e) => {
                    setActiveTab(2)
                    e.currentTarget.parentNode.querySelectorAll('.component__agentCall__info__tabs__single').forEach(item => {
                        item.classList.remove('component__agentCall__info__tabs__single--active')
                    })
                    e.currentTarget.classList.add('component__agentCall__info__tabs__single--active')
                }}
                    className="component__agentCall__info__tabs__single">{translate[language].aktivnost}</p>
                <p onClick={(e) => {
                    setActiveTab(3)
                    e.currentTarget.parentNode.querySelectorAll('.component__agentCall__info__tabs__single').forEach(item => {
                        item.classList.remove('component__agentCall__info__tabs__single--active')
                    })
                    e.currentTarget.classList.add('component__agentCall__info__tabs__single--active')
                }}
                    className="component__agentCall__info__tabs__single">{translate[language].historija}</p>
                
            </div>

            <div className="component__agentCall__info__tabsContent">
                {activeTab === 0 && <div className="component__agentCall__info__tabsContent__single component__agentCall__info__tabsContent__single--agent">
                   

                    <p className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p1}</p>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p2}</h3>
                    <p>{translate[language].p3}</p>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p4}</h3>
                    <p>{translate[language].p5}</p>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p6}</h3>
                    <p>{translate[language].p7}</p>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p8}</h3>
                    <p>{translate[language].p9}</p>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading" style={{
                        height: "1px",
                        display: "block",
                        padding: "10px 0px",
                        borderBottom: "2px solid black"
                    }}></h3>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p10}</h3>
                    <p>{translate[language].p11}</p>
                    <p><b>{translate[language].p12}</b>{translate[language].p13}</p>
                    <p><b>{translate[language].p14}</b>{translate[language].p15}</p>
                    <p><b>{translate[language].p16}</b>{translate[language].p17}</p>
                    <p><b>{translate[language].p18}</b>{translate[language].p19}</p>
                    <p><b>{translate[language].p20}</b>{translate[language].p21}</p>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p22}</h3>
                    <p>{translate[language].p23}</p>
                    <p>{translate[language].p24}</p>

                    <h3 className="component__agentCall__info__tabsContent__single__agentInfoHeading">{translate[language].p25}</h3>
                    <p>{translate[language].p26}</p>
                    <p>{translate[language].p27}</p>
                </div>}
                {activeTab === 1 && (!spinner ? <div className="component__agentCall__info__tabsContent__single">
                    <div className="component__agentCall__info__tabsContent__single__statusCodes">
                        {Object.keys(statusCodes?.leads).map((item, index) => {
                            if (!successfulCall && agentCallAttempts < 3 && !userData?.userData?.UserInfo?.Flags?.isShippmentAgent && leadPhone) return null;

                            if (!successfulCall && !props.lead.ResponsiblePersonBeforeReject) {
                                if ([
                                    "99",
                                    userData?.userData?.UserInfo?.Flags?.isShippmentAgent ? null : "100"
                                ].filter(t => t).includes(item)) return null;
                            };
                            if (!successfulCall && item === "99") return null;
                            if (item === "4") return null;
                            if (item === "97") return null;
                            if (item === '0') return null;
                            if (item === '50' && props.lead.Status !== 50) return null
                            if (item === '2' && props.lead.ResponsiblePersonBeforeReject && props.lead.ScheduledCallTimeAttempts >= 1 && props.lead.Status !== 4) return null;
                            return <p
                                key={index}
                                onClick={(e) => {

                                    if (item === '2') {
                                        if (!successfulCall && !props.lead.ResponsiblePersonBeforeReject) {
                                            setCallAgain(true);
                                            let tmpDate = moment().add(1, "hours");
                                            // if (props.lead.CallAttempts === 0) {
                                            //     tmpDate = moment().add(2, "hours");
                                            // }else if (props.lead.CallAttempts === 1) {
                                            //     tmpDate = moment().add(3, "hours");
                                            // }else {
                                            //     tmpDate = moment().add(4, "hours");
                                            // }

                                            setCallAgainFinal(tmpDate.toDate());
                                        } else {
                                            setCallAgain(false)
                                        };
                                    }
                                    if (item === '98' || item === '99') {
                                        setBadLeadComm(false)
                                    } else if (item === "100") {
                                        userDispatch(modalActions.addModal(<YesNoModal
                                            heading={translate[language].checkPriceModal}
                                            text={translate[language].checkPrice}
                                            textYes={translate[language].yesText}
                                            textNo={translate[language].noText}
                                            onYes={() => setLeadStatus(item)}
                                            onNo={() => null}
                                        />));
                                    } else {
                                        setLeadStatus(item)
                                    }
                                }}
                                id={statusCodes?.leads[item].replace(/\s/g, '').replace('š', 's')} className={item === String(leadStatus) ? "component__agentCall__info__tabsContent__single__statusCodes__text component__agentCall__info__tabsContent__single__statusCodes__text--active" : 'component__agentCall__info__tabsContent__single__statusCodes__text'}>
                                {translateStatusCodes(statusCodes?.leads[item])}</p>
                        })}
                    </div>
                    {callAgainFinal && <p className="component__agentCall__info__tabsContent__single__callAgain">Pozvati ponovo <span>{callAgainFinal.toLocaleDateString()}</span> u <span>{callAgainFinal.toLocaleTimeString()} </span> </p>}
                    {/* <p className="component__agentCall__info__tabsContent__single__callAgain">Broj obrada leada: {props.lead.ScheduledCallTimeAttempts}</p> */}

                    <p className="component__agentCall__info__tabsContent__single__callAgain"><strong> NAPOMENA: obavezno se predstavljajte pod imenom Mila. Klijentu koji naruci kapsule napomenite da cete mu poslati poruku viberom</strong></p>


                    <div className=" component__agentCall__info__tabsContent__single__dropdownContainer">
                        <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].ime}</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setLeadName(e.target.value);
                                }}
                                defaultValue={leadName ? leadName : props.lead.FirstLastName.split(" ")[0]} placeholder='Ime kupca' />
                        </div>
                        <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].prezime}</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setLeadLastName(e.target.value);
                                }}
                                defaultValue={leadLastName ? leadLastName : props.lead.FirstLastName.split(" ")[1]} placeholder='Prezime kupca' />
                        </div>
                        <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].drzava}</label>
                            <Dropdown theme='dark' accent="#6664E5" data={allCountries.map(country => {
                                return { name: country.CountryName + ' (' + country.CountryNumberPrefix + ')', value: country.CountryCode }
                            })}
                                selected={(() => {
                                    let id = null;
                                    if (leadCountry) {
                                        id = allCountries.indexOf(allCountries.find(t => t.CountryCode === leadCountry))
                                        if (id < 0) id = null
                                    } else if (props.lead.Country) {
                                        id = allCountries.indexOf(allCountries.find(t => t.CountryCode === props.lead.Country))
                                        if (id < 0) id = null
                                    };
                                    return id
                                })()}
                                onChange={(e) => {
                                    setLeadCountry(e?.value)
                                }} />
                        </div>
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].grad}</label>
                            <Dropdown theme='dark' accent="#6664E5" limit={500}
                                selected={(() => {
                                    let id = null;
                                    if (leadCity) {
                                        id = allCities.indexOf(allCities.find(t => t.CityID === leadCity))
                                        if (id < 0) id = null

                                    }
                                    return id
                                })()}
                                data={allCities.map((item) => {
                                    return { name: `${item.Name} ${item.PttNo}`, value: item.CityID }
                                })} onChange={(e) => {
                                    setLeadCity(e?.value)
                                }} />
                        </div>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].street}</label>
                            {/* bex!! {(leadCountry === 'RS' && addressesRS) ?
                                <Dropdown accent='#6664e5' theme={'dark'} inlinePlaceholder='Ulica'
                                    selected={(() => {
                                        let id = null;
                                        if (leadStreet) {
                                            id = addressesRS.indexOf(addressesRS.find(t => t.Name === leadStreet))
                                            if (id < 0) id = null
                                        } else if (props.lead.Street) {
                                            id = addressesRS.indexOf(addressesRS.find(t => t.Name === props.lead.Streat))
                                            if (id < 0) id = null
                                        };
                                        return id
                                    })()}
                                    data={(() => {
                                        let tmp = addressesRS?.map(e => {
                                            return { value: e.Name, name: e.Name };
                                        });

                                        tmp.push({ value: null, name: "Nova ulica", alwaysVisible: true, saveValue: true, showValue: true, style: { color: "#028202" } });
                                        return tmp;
                                    })()}
                                    onChange={e => setLeadStreet(e?.value)}
                                />
                                : <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                    onChange={(e) => {
                                        setLeadStreet(e.target.value)
                                    }}
                                    placeholder='Ulica'
                                    defaultValue={leadStreet ? leadStreet : props.lead.Address}
                                />} */}
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setLeadStreet(e.target.value)
                                }}
                                placeholder='Ulica'
                                defaultValue={leadStreet ? leadStreet : props.lead.Address}
                            />
                        </div>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].houseNumber}</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setLeadHouseNumber(e.target.value)
                                }} placeholder='Broj kuće'
                                defaultValue={leadHouseNumber ? leadHouseNumber : props.lead.HouseNumber}
                            />
                        </div>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].gender}</label>
                            <Dropdown defaultValue={selectedGender === null ? 'Nepoznato' : selectedGender === true ? 'Muški' : 'Ženski'}
                                data={[{ name: 'Muški', value: true }, { name: 'Ženski', value: false }]} theme="dark" accent="rgb(102, 100, 229)" onChange={(e) => {
                                    setSelectedGender(e.value)
                                }} selected={(() => {
                                    if (selectedGender !== true && selectedGender !== false) return -1;
                                    return [true, false].indexOf(selectedGender);
                                })()} />
                        </div>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].brthYear}</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setBirthYear(e.target.value)
                                }}
                                defaultValue={leadBirthYear} placeholder='Godina rođenja' type='number' />
                        </div>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">Broj telefona</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                disabled="disabled"
                                placeholder='Broj telefona'
                                value={String(leadPhone).substring(0, 6) + "*".repeat(String(leadPhone).length - 1)} />
                        </div>}

                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].expresInfo}</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setPostInfo(e.target.value)
                                }} placeholder='Dodatne informacija'
                                defaultValue={''}
                            />
                        </div>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer" style={{ display: "none" }}>
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">Email (za NutriProgram)</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setLeadEmail(e.target.value)
                                }} placeholder='Email'
                                defaultValue={''}
                            />
                        </div>}

                    </div>

                    <div className=" component__agentCall__info__tabsContent__single__dropdownContainer">
                        {leadCountry && <p className="component__agentCall__info__tabsContent__single__dropdownContainer__label" style={{ margin: "20px 0" }}>
                            {translate[language].artikli}
                            <CustomButtonSmall value={translate[language].add} style={{ marginLeft: "20px" }} accent="#6664E5" onClick={(e) => {
                                setSelectedArticles(art => [...art, { id: generateArticleID(), data: null }]);
                            }} />

                        </p>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles">
                            {selectedArticles.map(art => {
                                return <div key={art.id} className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle">
                                    <CustomButtonSmall value={translate[language].delete} accent="red" style={{ width: "70px", height: "45px" }} onClick={() => {
                                        setSelectedArticles(d => [
                                            ...d.filter(dt => dt.id < art.id),
                                            ...d.filter(dt => dt.id > art.id)
                                        ]);
                                    }} />
                                    {art.data === null ? <Dropdown inlinePlaceholder="Odaberite artikal" theme={'dark'}
                                        accent="#6664E5"
                                        style={{ margin: "10px 0px", width: "calc(100% - 80px)" }}
                                        data={(() => {
                                            let tmpArray = [];
                                            allArticles.map(curArticle => {
                                                const curCountry = allCountries?.find(t => t.ID === curArticle.CountryID);
                                                let CountryCurrency = "?";
                                                let countryPrefix = "";
                                                if (curCountry) {
                                                    CountryCurrency = curCountry.CountryCurrency;
                                                    countryPrefix = `(${curCountry.CountryCode}) `;
                                                };
                                                
                                                if (curArticle.ArticleName === "Gratis" && props.lead.isRecurring === false && !userData?.userData?.UserInfo?.Flags?.isShippmentAgent ) {
                                                } else {
                                                    tmpArray.push({ name: `${countryPrefix}${curArticle.ArticleName} - ${curArticle.ArticlePrice} ${CountryCurrency}`, value: curArticle.ID })
                                                }
                                            })
                                            return tmpArray;
                                        })()}

                                        onChange={e => {
                                            if (!e) return;
                                            setSelectedArticles(d => [
                                                ...d.filter(t => t.id < art.id),
                                                {
                                                    id: art.id,
                                                    data: {
                                                        id: e.value,
                                                        text: e.name,
                                                        quantity: 1
                                                    }
                                                },
                                                ...d.filter(t => t.id > art.id),
                                            ])
                                        }}
                                    /> : <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle__selected">
                                        <p>{art.data.text}</p>
                                        <p> {translate[language].value}</p>
                                        {(art.data.text.includes("Gratis") && props.lead.isRecurring === false &&  !userData?.userData?.UserInfo?.Flags?.isShippmentAgent ) ? <p>1</p> : <CustomInput theme='dark' accent="#6664E5" type="number" value={art.data.quantity} onChange={e => {
                                            let currInput = e.target.value;
                                            setSelectedArticles(d => {
                                                return d.map(dt => {
                                                    if (dt.id === art.id) {
                                                        let newVal = Number(currInput) ?? 0;
                                                        dt.data.quantity = newVal > 0 ? newVal : 1;
                                                    };
                                                    return dt;
                                                });
                                            });
                                        }} />}
                                    </div>}
                                </div>
                            })}
                        </div>}





                        {["BA", "RS"].includes(leadCountry) && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer--recurring">
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">{translate[language].reccuring}</label>
                            <CustomCheckbox theme="dark" accent="#6664e5" defaultValue={recurringPayment} onChange={(e) => setRecurringPayment(e)} />
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setRecurringPaymentJMBG(e.target.value)
                                }} placeholder='JMBG [13 karaktera)'
                                defaultValue={recurringPaymentJMBG}
                                style={{ display: recurringPayment ? null : "none" }}
                            />
                        </div>}

                        {leadCountry && <p className="component__agentCall__info__tabsContent__single__dropdownContainer__label" style={{ margin: "20px 0" }}>
                            {translate[language].oboljenja}
                            {(clientIllness[0] === "-1" && clientIllness.length === 1) ? null : <CustomButtonSmall value={translate[language].addGeneric} style={{ marginLeft: "20px" }} accent="#6664E5" onClick={(e) => {
                                setClientIllness(ci => [...ci, null]);
                            }} />}

                        </p>}
                        {leadCountry && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles">
                            {clientIllness.map((ill, illIdx) => {
                                return <div key={illIdx} className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle">
                                    <CustomButtonSmall value={translate[language].delete} accent="red" style={{ width: "70px", height: "45px" }} onClick={() => {
                                        setClientIllness(d => [
                                            ...d.filter((_, idx) => idx !== illIdx),
                                        ]);
                                    }} />
                                    {ill === null ? <Dropdown inlinePlaceholder="Odaberite oboljenje" theme={'dark'}
                                        accent="#6664E5"
                                        style={{ margin: "10px 0px", width: "calc(100% - 80px)" }}
                                        data={
                                            (() => {
                                                let tmp = Object.keys(illnessesSelector).sort((a, b) => {
                                                    return Number(a) > Number(b) ? 1 : -1;
                                                }).filter(ill => {
                                                    if (clientIllness.length > 1 && ill === "-1") return false;
                                                    return true;
                                                }).map(ill => {
                                                    return { value: ill, name: illnessesSelector[ill] ? illnessesSelector[ill]?.[language] ?? illnessesSelector[ill]?.["BA"] : ill.name }
                                                })

                                                tmp.push({ value: null, name: "Novo oboljenje", alwaysVisible: true, saveValue: true, showValue: true, style: { color: "#028202" } });
                                                return tmp;
                                            })()
                                        }
                                        onChange={e => {
                                            if (!e) return;
                                            setClientIllness(ci => {
                                                return ci.map((cim, cimIdx) => {
                                                    if (cimIdx !== illIdx) return cim;
                                                    return e?.value;
                                                });
                                            });
                                        }}
                                    /> : <div className="component__agentCall__info__tabsContent__single__dropdownContainer__articles__singleArticle__selected">
                                        {illnessesSelector[ill] ? <p>{illnessesSelector[ill][language] ?? illnessesSelector[ill]["BA"]}</p> : <p>{ill}</p>}

                                    </div>}
                                </div>
                            })}
                        </div>}

                        {selectedArticles.filter(t => t !== null).length > 0 && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer--sum">
                            <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer__spacer"></div>
                            {(() => {
                                let total = 0;
                                if (Array.isArray(allArticles)) {
                                    for (let item of selectedArticles) {
                                        if (item === null) continue;
                                        if (item.data === null) continue;

                                        let foundArticle = allArticles.find(a => a.ID === item.data.id);
                                        if (foundArticle) {
                                            total += Number(foundArticle.ArticlePrice) * Number(item.data.quantity);
                                        };
                                    };
                                };

                                let firstPart = (total / 100) * 60;
                                let secondPart = (total / 100) * 40;

                                total = Number(total).toFixed(2);
                                firstPart = Number(firstPart).toFixed(2);
                                secondPart = Number(secondPart).toFixed(2);

                                return <>
                                    <p>{translate[language].total}: {total}</p>
                                    {(recurringPayment && recurringPaymentJMBG) && <>
                                        <p>Prva rata: {firstPart}</p>
                                        <p>Druga rata: {secondPart}</p>
                                    </>}
                                </>
                            })()}
                            <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputContainer__spacer"></div>
                        </div>}

                        {/* {(doubleCheck === true && doubleCheckDone === false) && <DoubleCheck checked={setDoubleCheckDone} active={setActiveTab} />} */}




                        {/* {leadStatus === "100" && <>
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">Datum dostave (kada se paket pakuje i šalje - dan prije isporuke)</label>
                            <input className="component__agentCall__info__tabsContent__single__dropdownContainer__input"
                                onChange={(e) => {
                                    setDeliveryDate(moment(e.target.value).toDate().getTime());
                                }} type="date" value={moment(deliveryDate).format("YYYY-MM-DD")}
                            />
                        </>} */}


                        <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label">Datum dostave (kada se paket pakuje i šalje - dan prije isporuke)</label>
                        <div className="component__agentCall__info__tabsContent__single__dropdownContainer__inputList">
                            <button className={`component__agentCall__info__tabsContent__single__dropdownContainer__inputList__input ${checkDeliveryDateActive(0) ? "component__agentCall__info__tabsContent__single__dropdownContainer__inputList__input--active" : ""}`} onClick={() => setDeliveryDate(getDeliveryDates()[0].toDate().getTime())}>{getDeliveryDates()[0].toDate().toDateString()}</button>
                            <button className={`component__agentCall__info__tabsContent__single__dropdownContainer__inputList__input ${checkDeliveryDateActive(1) ? "component__agentCall__info__tabsContent__single__dropdownContainer__inputList__input--active" : ""}`} onClick={() => setDeliveryDate(getDeliveryDates()[1].toDate().getTime())}>{getDeliveryDates()[1].toDate().toDateString()}</button>
                            <button className={`component__agentCall__info__tabsContent__single__dropdownContainer__inputList__input ${checkDeliveryDateActive(2) ? "component__agentCall__info__tabsContent__single__dropdownContainer__inputList__input--active" : ""}`} onClick={() => setDeliveryDate(getDeliveryDates()[2].toDate().getTime())}>{getDeliveryDates()[2].toDate().toDateString()}</button>
                        </div>


                        {props.lead?.isRecurringWithGuarantee === true && <div className="component__agentCall__info__tabsContent__single__dropdownContainer__guaranteePrint" onClick={(e) => {
                            if (!shouldPrintGuarantee) return setShouldPrintGuarantee(d => !d);
                            return animateBox(e, <ConfirmNoGuarantee />);
                        }}>
                            <input type="checkbox" checked={shouldPrintGuarantee} />
                            <label className="component__agentCall__info__tabsContent__single__dropdownContainer__label" style={{ display: "inline-block", width: "auto", marginLeft: "20px" }}>Klijent želi garanciju</label>
                        </div>}


                    </div>

                    {badLeadComm === false && <div style={{
                        'minWidth': '200px',
                        'minHeight': '200px'
                    }} className="component__agentCall__info__tabsContent__single__callAgainModal">
                        <div className="component__agentCall__info__tabsContent__single__callAgainModal__modal" style={{ backgroundColor: '#3d3d55', width: '600px' }}>
                            <p style={{ 'fontFamily': 'Roboto', 'fontSize': '18px', color: 'white' }}>{translate[language].NavediteRazlogZbogCegaJeOvajLeadLos}</p>
                            <div style={{ 'display': 'flex', 'width': '100%', 'justifyContent': 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(230, 86, 233)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Dupli lead, paketje vec prodan'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '98'
                                            setLeadStatus('98')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].DupliLeadProdatPaket}</p>
                                </div>
                                <div style={{ 'width': '47%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(102, 100, 229)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Odbijen lead, kupac ne vjeruje u djelotvornost proizvoda'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '99'
                                            setLeadStatus('99')
                                        }
                                    })
                                }} >
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].KupacNeVjerujeUDjelotvornost}</p>
                                </div>
                                <div style={{ 'width': '47%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(230, 86, 233)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Loš lead, neispravan broj'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '98'
                                            setLeadStatus('98')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].NemoguceUspostavitiPoziv}</p>
                                </div>
                                <div style={{ 'width': '47%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(102, 100, 229)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Odbijen lead, kupcu je cijena previsoka'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '99'
                                            setLeadStatus('99')
                                        }
                                    })
                                }} >
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].KupcuJeSvakaPonudaPreskupa}</p>
                                </div>
                                <div style={{ 'width': '47%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(230, 86, 233)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Kupac tvrdi da nije nista narucio'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '98'
                                            setLeadStatus('98')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].PogresanBrojKlijentNijePorucio}</p>
                                </div>
                                <div style={{ 'width': '47%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(102, 100, 229)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Odbijen lead, kupac ne zeli razgovarati'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '99'
                                            setLeadStatus('99')
                                        }
                                    })
                                }} >
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].KupacNeZeliRazgovarati}</p>
                                </div>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(230, 86, 233)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Vise puta pozivan ne javlja se'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '98'
                                            setLeadStatus('98')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].VisePutaPozivanNeJavljaSe}</p>
                                </div>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(102, 100, 229)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Zeli se posavjetovati sa ljekarom'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '99'
                                            setLeadStatus('99')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].ZeliSavjetLjekara}</p>
                                </div>

                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(230, 86, 233)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Poslao upit informativno'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '98'
                                            setLeadStatus('98')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].PoslaoUpitInformativno}</p>
                                </div>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(102, 100, 229)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Ne zeli produziti terapiju'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '99'
                                            setLeadStatus('99')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].NeZeliProduzitiTerapiju}</p>
                                </div>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(230, 86, 233)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Los/Nepostojeci broj'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '98'
                                            setLeadStatus('98')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].LosNepostojeciBroj}</p>
                                </div>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(102, 100, 229)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Koristi drugi proizvod'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '99'
                                            setLeadStatus('99')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].KoristiDrugiProizvod}</p>
                                </div>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(230, 86, 233)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Govorna posta'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '98'
                                            setLeadStatus('98')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>Govorna posta</p>
                                </div>
                                <div style={{ 'width': ' 45%', 'margin': '10px 0', 'padding': '10px', 'backgroundColor': 'rgb(62 149 169)', 'borderRadius': '5px', 'cursor': 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} onClick={() => {
                                    axios({
                                        method: 'POST',
                                        url: `${backendModule.backendURL}/common/addComment`,
                                        data: {
                                            ReferenceName: 'lead',
                                            ReferenceID: props.lead.ID,
                                            Comment: 'Pozvati ponovo u drugoj smjeni'
                                        },
                                        ...backendModule.axiosConfig
                                    }).then(res => {
                                        if (res.data.status === 'ok') {
                                            setBadLeadComm(true)
                                            props.lead.Status = '99'
                                            setLeadStatus('99')
                                        }
                                    })
                                }}>
                                    <p style={{ textAlign: 'center', color: 'white' }}>{translate[language].PozvatiPonovoUSledecojSmjeni}</p>
                                </div>
                            </div>

                            <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red"
                                style={{ border: '1px solid white' }}
                                onClick={() => {
                                    setLeadStatus(props.lead.Status);
                                    setBadLeadComm(true)
                                }}
                            >{translate[language].Odustani}</button>
                        </div>
                    </div>}
                    {(leadStatus === '2' && callAgain === false && (successfulCall || props.lead.ResponsiblePersonBeforeReject)) && <div className="component__agentCall__info__tabsContent__single__callAgainModal">
                        <div className="component__agentCall__info__tabsContent__single__callAgainModal__modal" style={{ height: "400px" }}>
                            <h3>{translate[language].Skracenice}</h3>
                            <div style={{
                                margin: "10px 0px",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div className="component__agentCall__info__tabsContent__single__callAgainModal__modal__customButtons">
                                    <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + (1000 * 60 * 60 / 4)).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].Za15Minuta}</button>

                                    <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + (1000 * 60 * 60 / 2)).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].Za30Minuta}</button>

                                    <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + 1000 * 60 * 60).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].Za1Sat}</button>

                                </div>
                                <div className="component__agentCall__info__tabsContent__single__callAgainModal__modal__customButtons">
                                    <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + 1000 * 60 * 120).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].ZaDvaSata}</button>
                                    <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + 1000 * 60 * 180).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].ZaTriSata}</button>
                                    <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + 1000 * 60 * 60 * 4).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].ZaCetiriSata}</button>
                                    {/* <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + 1000 * 60 * 60 * 5).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].ZaPetSati}</button>
                                    <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red" onClick={(e) => {
                                        let tmp = new Date(Date.now());
                                        callAgainDate.current.value = tmp.toISOString().substring(0, 10);
                                        callAgainTime.current.value = moment(new Date().getTime() + 1000 * 60 * 60 * 6).format('HH:mm:ss');
                                        e.currentTarget.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                        e.currentTarget.classList.add("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active")
                                    }}>{translate[language].ZaSestSati}</button> */}
                                </div>
                                <br />
                            </div>

                            <div>
                                <label>{translate[language].UnesiteDatum}</label>
                                <input type='date' ref={callAgainDate} onChange={(e) => {
                                    e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                }} />
                                <label>{translate[language].UnesiteVrijeme}</label>
                                <input onChange={(e) => {
                                    e.currentTarget.parentNode.parentNode.parentNode.querySelectorAll("button").forEach(btn => btn.classList.remove("component__agentCall__info__tabsContent__single__callAgainModal__modal__red--active"));
                                }} type='time' ref={callAgainTime} step="60" />
                            </div>
                            <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__green" onClick={() => {
                                let tmp = new Date(`${callAgainDate.current?.value} ${callAgainTime.current?.value}`)
                                if (String(tmp) !== 'Invalid Date') {
                                    setCallAgain(true)
                                    setCallAgainFinal(tmp)
                                    props.lead.Status = 2;
                                }
                            }} >{translate[language].Potvrdi}</button>
                            <button className="component__agentCall__info__tabsContent__single__callAgainModal__modal__red"
                                onClick={() => {
                                    setLeadStatus(props.lead.Status);
                                }}
                            >{translate[language].Odustani}</button>

                        </div>
                    </div>}
                    {errorMsg && <p style={{ 'textAlign': 'center', 'marginTop': '20px', 'fontFamily': 'Roboto' }} >{errorMsg}</p>}
                    {!leadStatus && <p style={{ 'textAlign': 'center', 'marginTop': '20px', 'fontFamily': 'Roboto' }} >Molimo vas odaberite status leada</p>}
                    {leadStatus && (String(leadStatus) === '100' && leadCountry !== null && leadCity !== null && selectedGender !== null && selectedGender !== undefined && leadHouseNumber && leadPhone && leadName && selectedArticles.length > 0 && clientIllness.filter(c => c).length > 0 && leadBirthYear?.toString()?.length === 4 && selectedArticles?.find(t => t?.data === null) === undefined) && (recurringPayment ? recurringPaymentJMBG.length === 13 : true) ?
                        <div className="component__agentCall__info__tabsContent__single__saveButton" >
                            <button className="component__agentCall__info__tabsContent__single__saveButton__btn" onClick={() => { saveData() }}>{translate[language].Sacuvaj}</button>
                            {(canClose && sipSelector?.call?.status === "callStatus/IDLE") && <button className="component__agentCall__info__tabsContent__single__saveButton__btnRed" onClick={() => { finishLead() }} style={{
                                display: leadFinished ? "none" : "null"
                            }} >
                                {translate[language].Zavrseno}
                            </button>}
                        </div> : <p style={{ 'textAlign': 'center', 'marginTop': '20px', 'fontFamily': 'Roboto' }} >
                            {(() => {
                                if (leadCountry === null && String(leadStatus) === '100') {
                                    return "Izaberite drzavu kupca"
                                }
                                if (leadCity === null && String(leadStatus) === '100') {
                                    return "Izaberite grad kupca"
                                }
                                if ((selectedGender === null || selectedGender === undefined) && String(leadStatus) === "100") {
                                    return "Izaberite pol kupca"
                                }
                                if (!leadHouseNumber && String(leadStatus) === '100') {
                                    return "Unesite kucni broj"
                                }
                                if ((!leadName || !leadLastName) && String(leadStatus) === '100') {
                                    return "Ime i prezime kupca moraju biti uneseni"
                                }
                                if (selectedArticles.length === 0 && String(leadStatus) === '100') {
                                    return "Unesite proizvode"
                                }
                                if (leadBirthYear?.toString()?.length !== 4 && (String(leadStatus) === '100' || String(leadStatus) === "99")) {
                                    return "Unesite ispravno godinu rođenja kupca"
                                }
                                if (clientIllness.filter(c => c).length === 0 && (String(leadStatus) === '100' || String(leadStatus) === "99")) {
                                    return "Dodajte dodatno oboljenje kupca"
                                }
                            })()}
                        </p>}
                    {leadStatus && (String(leadStatus) === "99" && (successfulCall || agentCallAttempts >= 3 || userData?.userData?.UserInfo?.Flags?.isShippmentAgent) && selectedGender !== null && selectedGender !== undefined && leadBirthYear?.length === 4 && clientIllness.filter(c => c).length > 0) &&
                        <div className="component__agentCall__info__tabsContent__single__saveButton" >
                            <button className="component__agentCall__info__tabsContent__single__saveButton__btn" onClick={() => {
                                saveData()
                            }}>{translate[language].Sacuvaj}</button>
                            {((canClose) && sipSelector?.call?.status === "callStatus/IDLE") && <button className="component__agentCall__info__tabsContent__single__saveButton__btnRed" onClick={() => { finishLead() }} style={{
                                display: leadFinished ? "none" : null
                            }} >
                                {translate[language].Zavrseno}
                            </button>}
                        </div>}

                    {leadStatus && ((String(leadStatus) !== '100' && String(leadStatus) !== '99') && (successfulCall || agentCallAttempts >= 3 || userData?.userData?.UserInfo?.Flags?.isShippmentAgent)) &&
                        <div className="component__agentCall__info__tabsContent__single__saveButton" >
                            <button className="component__agentCall__info__tabsContent__single__saveButton__btn" onClick={() => {
                                saveData()
                            }}>{translate[language].Sacuvaj}</button>
                            {(canClose && sipSelector?.call?.status === "callStatus/IDLE") && <button className="component__agentCall__info__tabsContent__single__saveButton__btnRed" onClick={() => { finishLead() }} style={{
                                display: leadFinished ? "none" : null
                            }} >
                                {translate[language].Zavrseno}
                            </button>}
                        </div>}
                </div> : <Spinner align="center" color='#6664E5' />)}
                {activeTab === 3 && (!spinner ? <div className="component__agentCall__info__tabsContent__single">
                    {!leadHistory ? <Spinner align="center" color='#6664E5' style={{ marginTop: "20px" }} /> : leadHistory?.map((item, index) => {
                        return <div key={index} className="component__agentCall__info__tabsContent__single__history">
                            {chatUsers.map((elem, eindex) => {
                                if (item.UserID === elem.ID) {
                                    return <div key={eindex} className="component__agentCall__info__tabsContent__single__history__user">
                                        <div className="component__agentCall__info__tabsContent__single__history__user__info">
                                            <p >{elem.FirstName}  {elem.LastName}</p>
                                            <small className="component__agentCall__info__tabsContent__single__history__content__date">{new Date(item?.createdAt).toLocaleString()}</small>
                                        </div>
                                        <img alt='' src={elem?.Image ? elem?.Image : '/images/undefined.png'} />
                                    </div>
                                }
                            })}
                            <div className="component__agentCall__info__tabsContent__single__history__content">
                                <p>{item?.Comment}</p>
                            </div>
                        </div>
                    })}
                </div> : <Spinner align="center" color='#6664E5' />)}
                {activeTab === 2 && (!spinner ? <div className="component__agentCall__info__tabsContent__single">
                    <div className="component__agentCall__info__tabsContent__single__addComment">
                        <textarea placeholder="Komentar..." ref={comment}></textarea>
                        <button onClick={postComment}>Ostavi komentar</button>
                    </div>
                    {!comments ? <Spinner align="center" color='#6664E5' style={{ marginTop: "20px" }} /> : comments?.map((item, index) => {
                        if (item.Type === "comment") {
                            return <div key={index} className="component__agentCall__info__tabsContent__single__history">
                                {chatUsers.map((elem, eindex) => {
                                    if (item.UserID === elem.ID) {
                                        return <div key={eindex} className="component__agentCall__info__tabsContent__single__history__user">
                                            <div className="component__agentCall__info__tabsContent__single__history__user__info">
                                                <p><span>Komentar</span><span>{elem.FirstName}  {elem.LastName}</span></p>
                                                <small className="component__agentCall__info__tabsContent__single__history__content__date">{new Date(item?.createdAt).toLocaleString()}</small>
                                            </div>
                                            <img alt='' src={elem?.Image ? elem?.Image : '/images/undefined.png'} />
                                        </div>
                                    }
                                })}
                                <div className="component__agentCall__info__tabsContent__single__history__content">
                                    <p>{item?.Comment}</p>
                                </div>
                            </div>
                        } else if (item.Type === "sms") {
                            return <div key={index} className="component__agentCall__info__tabsContent__single__history">
                                {chatUsers.map((elem, eindex) => {
                                    if (item.UserID === elem.ID) {
                                        return <div key={eindex} className="component__agentCall__info__tabsContent__single__history__user">
                                            <div className="component__agentCall__info__tabsContent__single__history__user__info">
                                                <p><span>SMS</span><span>{elem.FirstName}  {elem.LastName}</span></p>
                                                <small className="component__agentCall__info__tabsContent__single__history__content__date">{new Date(item?.createdAt).toLocaleString()}</small>
                                            </div>
                                            <img alt='' src={elem?.Image ? elem?.Image : '/images/undefined.png'} />
                                        </div>
                                    }
                                })}
                                <div className="component__agentCall__info__tabsContent__single__history__content">
                                    <p>{item?.Comment}</p>
                                </div>
                            </div>
                        } else if (item.Type === "audio") {
                            return <div key={index} className="component__agentCall__info__tabsContent__single__history">
                                {chatUsers.map((elem, eindex) => {
                                    if (item.UserID === elem.ID) {
                                        return <div key={eindex} className="component__agentCall__info__tabsContent__single__history__user">
                                            <div className="component__agentCall__info__tabsContent__single__history__user__info">
                                                <p><span>Poziv</span><span>{elem.FirstName}  {elem.LastName}</span></p>
                                                <small className="component__agentCall__info__tabsContent__single__history__content__date">{new Date(item?.createdAt).toLocaleString()}</small>
                                            </div>
                                            <img alt='' src={elem?.Image ? elem?.Image : '/images/undefined.png'} />
                                        </div>
                                    }
                                })}
                                <div className="component__agentCall__info__tabsContent__single__history__content">
                                    <AudioPlayer timestamps={item.Timestamps ?? []} theme='dark' src={`${backendModule.backendURL}/staticContent/audioStatic/${item.Comment}`} accent="#6664e5" />
                                </div>
                            </div>
                        };
                    })}
                </div> : <Spinner align="center" color='#6664E5' />)}
               

            </div>
        </div>
        {/* <button onClick={(e) => { e.currentTarget.parentNode.style.opacity = 0; props.onClose() }} >SACUVAJ</button> */}
    </div>
}

export default LeadEditPostAgent;



